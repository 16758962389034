import React from 'react';
import { Grid, Button, Select, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DatePopover from './DatePopover';
import { dateFilterDropDownOptions, dateFilterDropDownValues, periodToDropDownOptions } from './DateFilterValues';

const DateFilter = ({ handleDateChanged, handleDropDownChanged, startDate, endDate, dropDownValue, period }) => {
  const { t } = useTranslation('layout');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSelectChange = (event) => {
    const value = dateFilterDropDownValues[event.target.value];
    handleDropDownChanged({ dropDownValue: event.target.value, period: value.period });
    handleDateChanged({ startDateValue: value.startDate?.format(), endDateValue: value.endDate?.format() });
  };

  const updateDropDownValue = (start, end) => {
    const today = moment(new Date());
    if (!today.startOf('day').isBetween(start, end) && !(today.format() === start.format() && today.format() === end.format())) {
      handleDropDownChanged({ dropDownValue: dateFilterDropDownOptions.Custom, period });
      return;
    }

    handleDropDownChanged({ dropDownValue: periodToDropDownOptions[period], period });
  };

  const increment = () => {
    const newStartDate = moment(startDate).add(1, period);
    const newEndDate = moment(endDate).add(1, period);
    if (period === 'month') newEndDate.endOf('month').startOf('day');
    updateDropDownValue(newStartDate, newEndDate);
    handleDateChanged({ startDateValue: newStartDate.format(), endDateValue: newEndDate.format() });
  };

  const decrement = () => {
    const newStartDate = moment(startDate).subtract(1, period);
    const newEndDate = moment(endDate).subtract(1, period);
    if (period === 'month') newEndDate.endOf('month').startOf('day');
    updateDropDownValue(newStartDate, newEndDate);
    handleDateChanged({ startDateValue: newStartDate.format(), endDateValue: newEndDate.format() });
  };

  const onDatesChange = (event) => {
    const startDateValue = event.startDateValue ? moment(event.startDateValue).startOf('day').format() : null;
    const endDateValue = event.endDateValue ? moment(event.endDateValue).startOf('day').format() : null;

    if (!moment(startDateValue).isValid() && !moment(endDateValue).isValid()) {
      handleDropDownChanged({ dropDownValue: dateFilterDropDownOptions.AllDate, period: undefined });
    } else {
      handleDropDownChanged({ dropDownValue: dateFilterDropDownOptions.Custom, period: undefined });
    }

    handleDateChanged({ startDateValue, endDateValue });
  };

  const toLocal = (date) => (date ? moment(date).local() : date);

  const getStartDateText = (value) => (value ? toLocal(value).format('YYYY-MM-DD') : '\u221e');

  const getEndDateText = (value) => (value ? ` / ${toLocal(value).format('YYYY-MM-DD')}` : ' / \u221e');

  const isSingleDate = () => moment(toLocal(startDate)).isSame(toLocal(endDate), 'day');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormControl>
          <Select native value={dropDownValue} onChange={handleSelectChange} data-testid="date-picker-period-select">
            <option value={dateFilterDropDownOptions.Today}>{t('dropDownValues.today')}</option>
            <option value={dateFilterDropDownOptions.ThisWeek}>{t('dropDownValues.thisWeek')}</option>
            <option value={dateFilterDropDownOptions.ThisMonth}>{t('dropDownValues.thisMonth')}</option>
            <option value={dateFilterDropDownOptions.AllDate}>{t('dropDownValues.allDates')}</option>
            {dropDownValue === dateFilterDropDownOptions.Custom && period === 'day' && (
              <option value={dateFilterDropDownOptions.Custom}>{t('dropDownValues.daily')}</option>
            )}
            {dropDownValue === dateFilterDropDownOptions.Custom && period === 'week' && (
              <option value={dateFilterDropDownOptions.Custom}>{t('dropDownValues.weekly')}</option>
            )}
            {dropDownValue === dateFilterDropDownOptions.Custom && period === 'month' && (
              <option value={dateFilterDropDownOptions.Custom}>{t('dropDownValues.monthly')}</option>
            )}
            {dropDownValue === dateFilterDropDownOptions.Custom && !period && (
              <option value={dateFilterDropDownOptions.Custom}>{t('dropDownValues.custom')}</option>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        {startDate && period && (
          <>
            <Button color="primary" onClick={decrement} aria-label="before">
              <NavigateBeforeIcon />
            </Button>
          </>
        )}
        <Button color="primary" onClick={handleClick}>
          {getStartDateText(startDate)}
          {!isSingleDate() && getEndDateText(endDate)}
        </Button>
        <DatePopover
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onDatesChange={onDatesChange}
          initialStartDate={toLocal(startDate)}
          initialEndDate={toLocal(endDate)}
        />
        {startDate && period && (
          <>
            <Button color="primary" onClick={increment} aria-label="next">
              <NavigateNextIcon />
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

DateFilter.propTypes = {
  handleDateChanged: PropTypes.func.isRequired,
  handleDropDownChanged: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dropDownValue: PropTypes.string,
  period: PropTypes.string,
};

DateFilter.defaultProps = {
  startDate: null,
  endDate: null,
  dropDownValue: dateFilterDropDownOptions.AllDate,
  period: undefined,
};

export default DateFilter;
